import React, { Fragment } from 'react';
import { H1, H2, H3, H4, BodyText, NormalButton, Accordion, FadeInSection } from '../../components';
import './index.scss';

const StyleGuide = () => {
  return (
    <div className="style-guide-container">
      <H1>Header One</H1>
      <br />
      <H2>Header Two</H2>
      <br />
      <H3>Header Three</H3>
      <br />
      <H4>Header Four</H4>
      <br />
      <BodyText className="tertiary-text">
        Here is an example of body copy.: <br />
        Our exclusive proprietary research and comprehensive analysis expertise, guide Mid-Market companies and Private
        Equity funds to better investment decisions. Our data-driven recommendations provide actionable insight to reach
        key business objectives.
      </BodyText>
      <br />
      <div className="buttons-container">
        <div className="button-main-container">
          <NormalButton type="normal" label="PRIMARY BUTTON - DARK" />
          <br />
          <NormalButton type="light" label="PRIMARY BUTTON - LIGHT" />
          <br />
          <NormalButton type="tertiary" label="TERTIARY BUTTON" />
        </div>
        <br />

        <BodyText className="tertiary-text">
          Button width can adjust to button label length (i.e. “what we do” button may be shorter in width than
          “industries we work with”.) <br />
          <br />
          This should not occur for buttons that are side by side. Buttons that are side by side should both be set at
          the max width of the linear button (i.e. hero buttons: transaction advisory and strategic growth should have
          the same width if possible).
        </BodyText>
      </div>
      <br />
      <br />
      <div className="accordion-container">
      </div>
    </div>
  );
};

export default StyleGuide;
